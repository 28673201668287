import React, { useState } from "react"

import Layout from "../components/layout"

const SignaturePage = () => {
  const [name, setName] = useState("김 제 니")
  const [englishName, setEnglishName] = useState("Jenny Kim")
  const [email, setEmail] = useState("jenny.kim")
  const [tel, setTel] = useState("010-0000-0000")
  const [department, setDepartment] = useState("서비스 UX팀")
  const [position, setPosition] = useState("매니저")

  const emptyAddStr = char => {
    if (char == "") {
      return char
    }
    // 연속된 공백을 치환 (필요없으면 제거)
    char = char.replace(/ +/gi, "")

    const len = char.length
    let resultStr = ""

    for (let i = 0; i < len; i++) {
      let str = char.substr(i, 1)
      resultStr += str + " "
    }
    return resultStr
  }
  return (
    <Layout>
      <div style={{ padding: "20px", width: "300px", display: "grid", gap: "10px" }}>
        <h2>전자서명 만들기</h2>
        <input placeholder="이름" onChange={e => setName(e.target.value)}></input>
        <input placeholder="영어이름" onChange={e => setEnglishName(e.target.value)}></input>
        <input placeholder="이메일 주소" onChange={e => setEmail(e.target.value)}></input>
        <input placeholder="휴대폰 번호" onChange={e => setTel(e.target.value)}></input>
        <input placeholder="부서" onChange={e => setDepartment(e.target.value)}></input>
        <input placeholder="직책" onChange={e => setPosition(e.target.value)}></input>
      </div>
      <div>
        <div dir="ltr">
          <br />
          <table
            width={330}
            cellSpacing={0}
            cellPadding={0}
            border={0}
            bgcolor="#ffffff"
            style={{
              fontFamily: 'AppleGothic, "맑은고딕", Malgun Gothic, "나눔고딕",NanumGothic, "돋움", Dotum, sans-serif',
              border: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <tbody>
              <tr>
                <td width={30} height={30} valign="top" />
                <td width={270} height={30} valign="top" />
                <td width={30} height={30} valign="top" />
              </tr>
              <tr>
                <td width={30} valign="top" />
                <td>
                  <h1
                    style={{
                      backgroundColor: "#3246fa",
                      width: 270,
                      height: 2,
                      margin: 0,
                    }}
                  />
                  <p style={{ margin: "10px 0 0 0" }}>
                    <span
                      style={{
                        fontFamily: '"Apple SD Gothic Neo", "Apple SD Gothic Neo",돋움, Dotum, sans-serif',
                        fontSize: 15,
                        fontWeight: 700,
                      }}
                    >
                      {emptyAddStr(name)} {englishName}
                    </span>
                    &nbsp;
                    <span style={{ fontSize: "1px" }}>&nbsp;</span>
                    &nbsp;
                    <span style={{ fontSize: 11, fontWeight: 400 }}>
                      {department}
                      <span style={{ color: "#d9d9d9" }}> | </span>
                      {position}
                    </span>
                  </p>
                  <p style={{ fontSize: 10, margin: "10px 0 0 0", color: "#6f6f6f" }}>
                    <span
                      style={{
                        fontFamily: '"Apple SD Gothic Neo", "Apple SD Gothic Neo",돋움, Dotum, sans-serif',
                        fontWeight: 700,
                      }}
                    >
                      E
                    </span>
                    &nbsp;
                    <span style={{ fontWeight: 400 }}>{email}@stagefive.com</span>
                    &nbsp;&nbsp;
                    <span
                      style={{
                        fontFamily: '"Apple SD Gothic Neo", "Apple SD Gothic Neo",돋움, Dotum, sans-serif',
                        fontWeight: 700,
                      }}
                    >
                      M
                    </span>
                    &nbsp;<span style={{ fontWeight: 400 }}>{tel}</span>
                  </p>
                  <p style={{ margin: "10px 0 0 0", color: "#6f6f6f" }}>
                    <span
                      style={{
                        fontFamily: '"Apple SD Gothic Neo", "Apple SD Gothic Neo",돋움, Dotum, sans-serif',
                        fontSize: 10,
                        fontWeight: 700,
                        color: "#000000",
                      }}
                    >
                      (주)스테이지파이브
                    </span>
                    &nbsp;
                    <span style={{ fontSize: 9, fontWeight: 400 }}>070-4402-7153</span>
                    <br />
                    <span style={{ fontSize: 10 }}>서울특별시 송파구 올림픽로 35길 123 향군타워 6F</span>
                  </p>
                  <h1
                    style={{
                      backgroundColor: "#f5f5f5",
                      width: 270,
                      height: 2,
                      margin: "10px 0",
                    }}
                  />
                  <img
                    src="https://shop-cdn.stagefive.io/email_logo.png"
                    width={140}
                    style={{
                      width: 140,
                    }}
                  />
                </td>
                <td width={30} valign="top" />
              </tr>
              <tr>
                <td width={30} height={30} valign="top" />
                <td width={270} height={30} valign="top" />
                <td width={30} height={30} valign="top" />
              </tr>
              <tr />
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default SignaturePage
